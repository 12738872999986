html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  /* overflow: hidden; */
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  background-color: #fff;
  height: calc(100vh - 200px);
  margin-top: 68px;
  margin-bottom: 92px;
  box-sizing: border-box;
  overflow: hidden;
}

.login-box {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.welcome_text {
  color: #002677;
}

.login-btn {
  background-color: #002677;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
}

.illustration {
  margin-left: 2rem;
}

.illustration img {
  max-width: 100%;
  height: auto;
}